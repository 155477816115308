import React, { Component } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import smoothscroll from 'smoothscroll-polyfill';
import Tag from './tag';
import TextSection from './textSection';
import heroImage from '../images/human-illustration.svg';
import { Link } from 'gatsby'
import { SectionHeader } from './HelperComponents';


const StyledWrapperHero = styled.div`
  display: flex;
  justify-content: space-between;

  @media(max-width: 1184px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
    justify-items: center;
    width: 95%;
    margin: 0 auto 40px;
  }
`;

const HeroTextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  order: ${props => props.reversed ? '1': '0'};
  width: 60%;

  .responsiveTag {
    display: none;
    color: ${props => props.color ? props.color : 'initial'};
  }

  @media(max-width: 1184px) {
    order: 0;
    text-align: center;
    width: 100%;
    margin-right: 0;

    .responsiveTag {
      display: block;
    }
  }
`;

const Hero = styled.div`
  position: relative;
  width: fit-content;
`;

const HeroImg = styled.img`
  height: 80vh;
  width: auto;
  opacity: 0;
  margin-bottom: 50px;

  @media(max-width: 1184px) {
    height: 65vh;
  }
`;

const HeroImgAnimate = posed(HeroImg)({
  visible: {
    opacity: 1,
    transition: { duration: 5000 },
  },
  hidden: {
    opacity: 0,
  }
})

const HeroButtonsWrapper = styled.div`
  margin-top: 28px;

  @media(max-width: 1184px) {
    display: flex;
    justify-content: space-around;
  }
`;

const HeroButton = styled.button`
  padding: 18px 30px;
  font-size: 16px;
  border-radius: 5px;
  background-color: ${props => props.red ? '#ff413d' : '#213fca'};
  color: #fff;
  margin-right: 30px;
  cursor: pointer;

  @media(max-width: 1184px) {
    padding: 11px 24px;
    font-size: 11px;
    color: #fff;
    width: 160px;
    margin-bottom: 15px;
    margin-right: 0;
  }
`;

const AboutDiseaseAnimate = posed.div({
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  }
});

const BigDiseaseTitle = styled.div`
  @media(max-width: 1184px) {
    display: none;
  }
`;

const ResponsiveDiseaseTitle = styled.h2`
  display: none;
  color: ${props => props.color ? props.color : 'initial'};

  @media(max-width: 1184px) {
    display: block;
  }
`;

const DiseaseDot = styled.div`
  height: 20px;
  width: 20px;
  position: absolute;
  margin-top: -10px;
  margin-left: -10px;
  border: 4px solid #fff;
  background-clip: padding-box;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s;
  z-index: ${props => props.isActive ? '0' : '1'};
  top: ${props => props.positionTop};
  left: ${props => props.positionLeft};
  background-color: ${props => props.color};
  height: ${props => props.isActive ? '65px' : null};
  width: ${props => props.isActive ? '65px' : null};
  background-color: ${props => props.isActive ? '#fff' : ''};
  border: ${props => props.isActive ? `25px solid rgba(123, 146, 255, 0.53)` : ''};
  margin-top: ${props => props.isActive ? `-32px` : ''};
  margin-left: ${props => props.isActive ? `-32px` : ''};
  opacity: 0;

  :hover {
    transform: scale(1.5);
  }
`;

const DiseaseDotAnimate = posed(DiseaseDot)({
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  }
})


class DoctorDiseasesSection extends Component {
  state = {
    currentDiseaseId: 0,
    animate: false,
    intervalId: null,
  }

  componentDidMount() {
    smoothscroll.polyfill();

    this.setState({
      displayHeroElement: true
    }, () => {
      let intervalId = setInterval(this.timer, 10000);
      this.setState({ intervalId});
    })
  }

  animateDiseaseChange = (id) => {
    this.setState({
      animate: true
    });

    setTimeout(() => {
      this.setState({
        animate: false,
        currentDiseaseId: id
      })
    }, 600)
  }

  timer = () => {
    const newDiseaseId = this.state.currentDiseaseId + 1;
    if (newDiseaseId >= this.props.diseaseDescriptions.length) {
      this.animateDiseaseChange(0);
    } else {
      this.animateDiseaseChange(newDiseaseId);
    }
  }

  changeSelectedDisease = (id) => {
    clearInterval(this.state.intervalId);
    let intervalId = setInterval(this.timer, 10000);
    this.setState({ intervalId: intervalId });
    this.animateDiseaseChange(id);
  }

  scrollToContactSection = () => {
    let scrollOffset = 30;
    if (window.innerWidth > 1184) {
      scrollOffset = 95;
    }
    const contact = document.getElementById('contact').offsetTop;
    window.scrollTo({
      top: contact + scrollOffset,
      behavior: 'smooth'
    })
  }

  render() {
    const aboutDisease = (
      <AboutDiseaseAnimate
        pose={this.state.animate ? 'hidden' : 'visible'}>
        <BigDiseaseTitle>
          <TextSection
            title={this.props.diseaseDescriptions[this.state.currentDiseaseId].title}
            text={this.props.diseaseDescriptions[this.state.currentDiseaseId].description} 
            headersColor={this.props.headersColor}
            textColor={this.props.textColor} />
        </BigDiseaseTitle>
        <ResponsiveDiseaseTitle color={this.props.headersColor}>
          {this.props.diseaseDescriptions[this.state.currentDiseaseId].title}
        </ResponsiveDiseaseTitle>
        <HeroButtonsWrapper>
          <HeroButton onClick={this.scrollToContactSection} red>Sprechstunde vereinbaren</HeroButton>
          <Link to={`/wiki?article=${this.props.diseaseDescriptions[this.state.currentDiseaseId].diseaseSlug}`}>
            <HeroButton>Mehr erfahren</HeroButton>
          </Link>
        </HeroButtonsWrapper>
      </AboutDiseaseAnimate>
    )

    return (
      <React.Fragment>
        <StyledWrapperHero id="heroSection">
          <HeroTextWrapper reversed={this.props.reversed} color={this.props.textColor}>
            {/* <Tag tag="Schwerpunkte" color={this.props.headersColor} />
            <p className="responsiveTag">Schwerpunkte</p> */}
            <SectionHeader title="Schwerpunkte" />
            {aboutDisease}
          </HeroTextWrapper>
          <Hero>
            <HeroImgAnimate
              pose={this.state.displayHeroElement ? 'visible' : 'hidden'}
              id="heroElement"
              src={heroImage}
              alt="hero" />

            {
              this.props.diseaseDescriptions.map((disease, index) => (
                <DiseaseDotAnimate key={index}
                  pose={this.state.displayHeroElement ? 'visible' : 'hidden'}
                  isActive={index === this.state.currentDiseaseId}
                  onClick={() => this.changeSelectedDisease(index)}
                  color={disease.color}
                  positionTop={disease.positionTop}
                  positionLeft={disease.positionLeft}
                />
              ))
            }
          </Hero>
        </StyledWrapperHero>
      </React.Fragment>
    )
  }
}

export default DoctorDiseasesSection;