import React, { Component } from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from "../components/Header"
import Footer from '../components/footer'
import ContactSection from '../components/ContactSection'
import {initMap} from '../utils/utils'
import DoctorInfo from '../components/DoctorInfo'
import lachat_mario_1 from '../images/lachat_mario_1.jpg'
import lachat_mario_2 from '../images/lachat_mario_2.png'
import lachat_mario_3 from '../images/lachat_mario_3.png'
import DoctorDiseasesSection from '../components/DoctorDiseasesSection';
import path1 from "../images/path-01-light.svg";
import path2 from "../images/path-02-light.svg";
import { SectionHeader } from '../components/HelperComponents'

const TitleSection = styled.div`
  margin: 160px 0 120px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;

  p {
    color: ${props => props.color ? props.color : 'initial'};
  }

  @media(max-width: 1184px) {
    align-items: flex-start;
    text-align: left;
    margin: 80px auto 24px;
    width: 95%;
  }
`;

const TagSection = styled.div`
  display: flex;
  align-items: center;
`;

const Tag = styled.h1`
  font-size: 32px;
  line-height: 78px;
  margin-top: 1px;
  color: ${props => props.color ? props.color : 'initial'};

  @media(max-width: 1184px) {
    line-height: 45px;
  }
`;

const LineLeft = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, ${props => props.color ? props.color : 'rgba(255,255,255,1)'} 100%);
  margin-right: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const LineRight = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, ${props => props.color ? props.color : 'rgba(255,255,255,1)'} 100%);
  margin-left: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
`;

const Year = styled.p`
  color: #3c3c3c;

  @media(max-width: 1184px) {
    color: #c8d2ff;
  }
`;

const ContactSectionOuter = styled.div`
  background-color: #213fca;
  z-index: 1;
`;

const ContactSectionInner = styled.div`
  width: 90%;
  margin: 95px auto 0;
  position: relative;

  @media(min-width: 1920px) {
    width: 70%;
  }

  @media(min-width: 2000px) {
    width: 60%;
  }

  @media(max-width: 1184px) {
    width: 100%;
  }
`;

const renderCvContent = () => (<div className="cv-content-wrapper">
  <ul class="no-padding">
    <li>
      <Year><strong>2019</strong></Year>
      <p>Co-Leiter Aorten- und GefässZentrum Zürich</p>
    </li>
    <li>
      <Year><strong>2002 - 2018</strong></Year>
      <p>Leiter Gefässchirurgie, UniversitätsSpital Zürich</p>
    </li>
    <li>
      <Year><strong>2002 - 2015</strong></Year>
      <p>Stellvertrettender Klinikdirektor, Leitenderarzt Herzchirurgie, Klinik für Herz- und Gefässchirurgie, UniversitätsSpital Zürich</p>
    </li>
  </ul>
  <br/>

  <p><strong>Facharzttitel:</strong></p>
  <ul class="no-grid">
    <li>• Chirurgie (FMH)</li>
    <li>• Herz- und thorakale Gefässchirurgie (FMH)</li>
    <li>• Gefässchirurgie (FMH)</li>
    <li>• European  Board  of  Thoracic  and  Cardiovascular Surgeons (FETCS)</li>
    <li>• European Board of Vascular Surgery (FEBVS)</li>
  </ul>
  <br/>
  <p><strong>Dissertation:</strong></p>
  <p><strong>Long-term outcomes of coronary artery bypass grafting: saphenous vein graft versusmammary artery graft</strong></p>
  <br/>
  <p><strong>Habilitation:</strong></p>
  <p>Advances in the treatment of thoracic and abdominal aortic aneurysms</p>
</div>);


class DrLachatPage extends Component {
  componentDidMount() {
    window.google && initMap();
  }

  render() {
    return (
      <>
        <Header />
        <Layout
          background="#e0edff"
          images={[
            {
              path: path1,
              styles: {top: '300px', left: '30%'},
            },
            {
              path: path2,
              styles: {top: '40%', left: '20px'},
            }
          ]}
        >
          <SEO title="Dr. Lachat" />
          
          <TitleSection color="#3c3c3c">
            <TagSection>
              <LineLeft color="#101f66" />
              <Tag color="#101f66">Prof. Dr. Mario Lachat</Tag>
              <LineRight color="#101f66" />
            </TagSection>
            <Subtitle>Aorten – und Gefässspezialist Zürich</Subtitle>
            <br/>
            <p>Prof. Dr. Mario Lachats ist Herz- und Gefässchirurg mit Tätigkeitsschwerpunkt in der (insbesondere komplexen) Aortenchirurgie, wo er wesentliche technische Beiträge zur Vereinfachung und Sicherheit der offenen Chirurgie geleistet hat. Prof. Mario Lachat gilt als schweizerischer und internationaler Pionier der endovaskulären Aortenchirurgie und ist Erfinder und Mitentwickler zahlreicher, endovaskulärer Prothesen. Zudem verfügt er über eine der weltweit grössten Erfahrungen in der minimal-invasiven endovaskulären Behandlung des Aortenbogens.</p>
          </TitleSection>

          <DoctorInfo 
            reverseColumns={true}
            name="Prof. Dr. med. Mario Louis Lachat"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam fringilla libero laoreet, euismod nulla pellentesque, pharetra purus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Curabitur a felis in dui malesuada viverra. Duis sodales turpis blandit libero sollicitudin efficitur. Nulla facilisi. Mauris venenatis turpis interdum nisl feugiat sodales. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris sed elit eget dui efficitur vestibulum. Curabitur vitae dui in neque maximus cursus et vel augue. Maecenas nec velit at mi volutpat porta quis non augue."
            cvContent={renderCvContent()}
            headersColor="#101f66"
            textColor="#3c3c3c"
            infoButtonsColor="#ff813d"
            photo1={lachat_mario_1}
            photo2={lachat_mario_3}
            photo3={lachat_mario_2}
            texts={[
              {
                tag: 'Prof. Dr. Lachat',
                title: 'Werdegang',
                text: function() {return (<div className="content-wrapper">
                  <p>Professor Dr. med. Mario Lachat ist in Lausanne geboren und hat sein Medizinstudium an der Universität Lausanne abgeschlossen. Seine chirurgische Laufbahn bestritt er später vorwiegend am UniversitätsSpital Zürich. Dabei durchlief er eine Ausbildung zum Chirurgen, danach zum Herzchirurgen und schlussendlich zum Gefässchirurgen. Er hat während seiner Aus- und Weiterbildung in zahlreichen internationalen Institutionen (USA, Kanada, Asien, Europa) gastiert. Er war 14 Jahre lang stellvertretender Klinikdirektor der Klinik für Herz- und Gefässchirurgie und Leiter der Gefässchirurgie am UniversitätsSpital Zürich. Dabei hat er die universitäre Gefässchirurgie während 18 Jahren geleitet.</p>
                </div>)}(),
              },
              {
                tag: 'Prof. Dr. Lachat',
                title: 'Zahlen & Fakten',
                text: function() {return (<div className="content-wrapper">
                  <p>Prof. Mario Lachat hat weit über 500 Patienten mit erkrankter Hauptschlagader in den Bereichen des Aortenbogens und/ oder thorako-abdominalen Aorta erfolgreich behandelt. Insbesondere verfügt er nach über 200 behandelten Patienten eine der weltweit grössten Erfahrung in der endovaskulären Versorgung des Aortenbogens.</p>
                  <br/>
                  <p>Er hat weit über 1000 Patienten mit Hauptschlagaderkrankheit minimal-invasiv, respektive endovaskulär behandelt. Davon > 300 Patienten mit Beteiligung des Aortenbogens und/oder der viszeralen Aorta. Dazu hat er hunderte konventionelle und/oder hybride Aortenoperationen durchgeführt.</p>
                  <br/>
                  <p><strong style={{color: '#3c3c3c'}}>Mitgliedschaften:</strong></p>
                  <p>Swiss Society of Medicine, Swiss Society of Surgery, Swiss Society of Cardiac and Thoracic Vascular Surgery, Swiss Society of Vascular Surgery, Foederatio Medicorum Scrutantium, European Society of Vascular Surgery, European Society of Thoracic and Cardiovascular Surgeons, International Society for Vascular Surgery, European Surgical Association  Zudem ist er Medical Co-Director bei Endospan, einem israelischen Startup, das sich der Entwicklung neuartiger Aortenendoprothesen widmet. In seiner Funktion hält er eine Option auf einem Aktienpaket (1%)</p>
                </div>)}(),
              },
              {
                tag: 'Prof. Dr. Lachat',
                title: 'CV',
                text: renderCvContent(),
              },
              {
                tag: 'Prof. Dr. Lachat',
                title: 'Persönlich',
                text: '...',
                disabled: true,
              },
              {
                tag: 'Prof. Dr. Lachat',
                title: 'Schwerpunkte',
                text: function() {return (<div className="content-wrapper">               
                  <p><strong style={{color: '#3c3c3c'}}>Aortenchirurgie:</strong></p>
                  <p>Ganzes Spektrum. Von der Aortenwurzel bis zur Bifurkation. Komplexe Pathologien des Aortenbogens / der thorakoabdominalen Aorta. Konventionelle, offene Chirurgie, Hybrid- oder komplett endovaskuläre Verfahren. Aortendissektion, sowie Bindegewebserkrankungen (u.a. Marfan, Ehler-Danlos)</p>
                  <br/>
                  <p><strong style={{color: '#3c3c3c'}}>Arterielle Gefässchirurgie:</strong></p>
                  <p>Ganzes Spektrum (Bypasschirurgie, lokale Rekonstruktionen) inklusive supraaortale Chirurgie (Carotis und Armarterien), renoviscerale revaskularisationschirurgie, periphere Gefässchirurgie (Bypasschirurgie an Ober- und Unterschenkel)</p>
                  <br/>
                  <p><strong style={{color: '#3c3c3c'}}>Venöse Chirurgie:</strong></p>
                  <p>Vena cava inferior und superior (Ersatz, Rekonstruktionen), Varizenchirurgie (Stripping und endoluminale Verödungen)</p>
                  <br/>
                  <p><strong style={{color: '#3c3c3c'}}>Wundbehandlung:</strong></p>
                  <p>Gesamtes Spektrum, inklusive operative Versorgung</p>
                  <br/>
                  <p><strong style={{color: '#3c3c3c'}}>Spezialisierte Tumorchirurgie:</strong></p>
                  <p>Falls Einbezug von grossen Gefässen im Thorax oder Abdomen und der Extremitäten. Mehrheitlich ohne Anwendung der Herz-Lungenmaschine.</p>
                  <br/>
                  <p><strong style={{color: '#3c3c3c'}}>Konsiliarische Tätigkeit:</strong></p>
                  <p>Sprechstunden, Beratungen, Second Opinion und Begutachtungen</p>
                  <br/>
                </div>)}(),
              },
            ]}
          />

        </Layout>

        <ContactSectionOuter>
          <ContactSectionInner>
            <ContactSection doctor='dr-lachat' title='Contact Dr. Lachat' background="#213fca" mailTitle="Prof. Dr. med. Mario Louis Lachat" />
          </ContactSectionInner>
        </ContactSectionOuter>

        <Footer />
      </>
    )
  }
}

export default DrLachatPage
