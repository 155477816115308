import React, { Component } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import Tag from './tag';
import TextSection from './textSection';
import Modal from './modal';
import clientsIcon from '../images/clients-icon.svg';
import cvIcon from '../images/cv-icon.svg';
import arrowRightIcon from '../images/arrow-right.svg';


const LearnMoreStyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 126px;
  margin-bottom: 220px;

  @media(max-width: 1184px) {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    margin: 0 auto;
    padding: 40px 2.5%;
  }
`;

const ResponsiveLearnMoreHeader = styled.div`
  display: none;

  @media(max-width: 1184px) {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  p, h2 {
    color: ${props => props.headersColor ? props.headersColor : 'initial'};
  }  
`;

const ImageSection = styled.div`
  display: grid;
  order: ${props => props.reverseColumns === true ? '1' : '0'};

  grid-auto-rows: 115px;
  grid-template-areas:
    "imgTop ."
    "imgTop infoButtonTop"
    "infoButtonBottom imgMiddle"
    "imgBottom imgMiddle"
    "imgBottom .";
  grid-gap: 20px;
  justify-content: center;

  @media(max-width: 1184px) {
    grid-template-areas:
    "imgTop imgMiddle";
    grid-auto-rows: auto;
    grid-gap: 10px;

    order: 0;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s;
  transform: ${props => props.checked ? 'scale(1.2)' : 'scale(1)'};

  @media(min-width: 1184px) {
    &:hover {
      transform: scale(1.2);
    }
  }

  @media(max-width: 1184px) {
    border-radius: 15px;
    transform: none;
    max-width: 250px;
    max-height: 250px;
    width: auto;
    height: auto;

  }
`;

const ImageWrapperTop = styled(ImageWrapper)`
  border-radius: 25px 5px 5px 5px;
  transform-origin: bottom right;
  grid-area: imgTop;
`;

const ImageWrapperMiddle = styled(ImageWrapper)`
  border-radius: 5px 5px 25px 5px;
  transform-origin: top left;
  grid-area: imgMiddle;
`;

const ImageWrapperBottom = styled(ImageWrapper)`
  border-radius: 5px 5px 5px 25px;
  transform-origin: top right;
  grid-area: imgBottom;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .image-text {
    opacity: ${props => props.visible ? '1' : '0'};
  }

  &:hover {
    .image-text {
      padding-right: 10px;
      transition: all 0.3s;
    }
  }
`;

const ImageText = styled.div`
  position: absolute;
  width: 100%;
  padding: 20px;
  bottom: 0px;
  color: #fff;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s;
  background: linear-gradient(180deg, rgba(0, 13, 78, 0.0) 0%, rgba(0 , 13 , 78, 0.25) 100%);
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s;

  @media(max-width: 1184px) {
    border-radius: 15px;
    transform: none;
    max-width: 100%;
    height: 100%;
  }
`;

const Icon = styled.img`
`;

const P = styled.p`
  color: #fff;
  line-height: initial;
`;

const InfoButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  height: 100%;
  background-color: #203bbc;
  padding: 26px 50px 31px 45px;
  transition: all 0.3s;
  cursor: pointer;

  :hover {
    transform: scale(1.2);
    background-color: #ff413d;

    P {
      color: #fff;
    }
  }

  @media(max-width: 1184px) {
    display: none;
  }
`;

const InfoButtonTop = styled(InfoButton)`
  border-radius: 5px 25px 5px 5px;
  transform-origin: bottom left;
  grid-area: infoButtonTop;
  background-color: ${props => props.background ? props.background : 'initial'};
  
  p {
    font-size: 1.3em;
  }
  
  img {
    margin-left: 10px;
  }
`;

const InfoButtonBottom = styled(InfoButton)`
  align-self: flex-end;
  transform-origin: center right;
  border-radius: 25px 5px 5px 25px;
  grid-area: infoButtonBottom;
  background-color: ${props => props.background ? props.background : 'initial'};
`;

const RightColumn = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-self: baseline;

  .cv-content-wrapper {
    ul {
      padding: 8px 16px;

      &.no-padding {
        padding: 0;
      }

      li {
        color: #3c3c3c;
        font-size: 16px;

        @media(max-width: 1184px) {
          font-size: 12px;
        }
      }
    }
  }
  
  .cv-content-wrapper li {
    display: flex;
    
    p:nth-of-type(1) {
      width: 150px;
    }
    
    p:nth-of-type(2) {
      flex: 1;
    }
  }
`;

const TextSectionWrapper = posed.div({
  visible: {
    applyAtStart: { display: 'block' },
    opacity: 1,
    transition: { duration: 2000 }
  },
  hidden: {
    opacity: 0,
    applyAtStart: { display: 'none' },
  }
});

const ShowCvButtonWrapper = styled.div`
  margin-bottom: 140px;
`

const ShowCvButton = styled.button`
  display: none;
  padding: 11px 16px;
  font-size: 11px;
  color: #101f66;
  width: 160px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #101f66;
  border-radius: 5px;
  justify-content: center;

  @media(max-width: 1184px) {
    display: flex;
    margin: 15px auto;
    margin-bottom: 40px;
  }
`;

const CVHeader = styled.h2`
  margin-bottom: 30px;
`;


class DoctorInfo extends Component {
  state = {
    displayCV: false,
    checkedItem: 'topImage',
    activeText: 0,
  }

  checkImage = (clickedImage) => {
    // const imageChange = this.state.checkedItem === clickedImage ? null : clickedImage;
    const imageChange = clickedImage;
    this.setState({
      displayCV: false,
      checkedItem: imageChange
    });
  }

  hideCv = () => {
    this.setState({ displayCV: false });
  }

  openCV = () => {
    this.setState({
      displayCV: true,
      checkedItem: null
    });
  }

  render() {
    return (
      <React.Fragment>
        <span id="uberMichAnchor" className="anchor"></span>
        <LearnMoreStyledWrapper id="uberMich" className="scrollSection">
          <Modal show={this.state.displayCV} clicked={this.hideCv}>
            <CVHeader>CV {this.props.name}</CVHeader>
            {this.props.cvContent}
          </Modal>

          <ImageSection reverseColumns={this.props.reverseColumns}>
            
            <ImageWrapperTop 
              top
              checked={'topImage' === this.state.checkedItem}
              onClick={() => {
                if (!this.props.texts[0].disabled) {
                  this.checkImage('topImage')
                  this.setState({activeText: 0})
                }
              }}>
              <Img src={this.props.photo1} alt={this.props.name} />
              <ImageOverlay visible={'topImage' !== this.state.checkedItem}>
                <ImageText className="image-text">
                  <span>Werdegang</span>
                  { !this.props.texts[0].disabled && <img src={arrowRightIcon} alt='arrow' /> }
                </ImageText>
              </ImageOverlay>
            </ImageWrapperTop>

            <InfoButtonTop
              background={this.props.infoButtonsColor}
              onClick={() => {
                if (!this.props.texts[1].disabled) {
                  this.setState({activeText: 1})
                  this.checkImage(null)
                }
              }}>
              <div>
                <P>Zahlen & Fakten</P>
              </div>
              <Icon src={clientsIcon} alt="clients" />
            </InfoButtonTop>
            <InfoButtonBottom
              background={this.props.infoButtonsColor}
              onClick={() => {
                if (!this.props.texts[2].disabled) {
                  this.setState({activeText: 2})
                  this.checkImage(null)
                }
              }}>
              <Icon src={cvIcon} alt="CV" />
              <h3>CV</h3>
            </InfoButtonBottom>

            <ImageWrapperMiddle
              checked={'middleImage' === this.state.checkedItem}
              onClick={() => {
                if (!this.props.texts[3].disabled) {
                  this.checkImage('middleImage')
                  this.setState({activeText: 3})
                }
              }}>
              <Img src={this.props.photo2} alt={this.props.name} />
              <ImageOverlay visible={'middleImage' !== this.state.checkedItem}>
                <ImageText className="image-text">
                  <span>Persönlich</span>
                  { !this.props.texts[3].disabled && <img src={arrowRightIcon} alt='arrow' /> }
                </ImageText>
              </ImageOverlay>
            </ImageWrapperMiddle>

            <ImageWrapperBottom
              checked={'bottomImage' === this.state.checkedItem}
              onClick={() => {
                if (!this.props.texts[4].disabled) {
                  this.checkImage('bottomImage')
                  this.setState({activeText: 4})
                }
              }}>
              <Img src={this.props.photo3} alt={this.props.name} />
              <ImageOverlay visible={'bottomImage' !== this.state.checkedItem}>
                <ImageText className="image-text">
                  <span>Schwerpunkte</span>
                  { !this.props.texts[4].disabled && <img src={arrowRightIcon} alt='arrow' /> }
                </ImageText>
              </ImageOverlay>
            </ImageWrapperBottom>

          </ImageSection>
          
          <ResponsiveLearnMoreHeader headersColor={this.props.headersColor} >
            <p>{this.props.texts && this.props.texts[this.state.activeText].tag}</p>
            <h2>{this.props.texts && this.props.texts[this.state.activeText].title}</h2>
          </ResponsiveLearnMoreHeader>

          <RightColumn>
            <Tag tag={this.props.texts && this.props.texts[this.state.activeText].tag} color={this.props.headersColor} />
            <TextSectionWrapper>
              <TextSection
                title={this.props.texts && this.props.texts[this.state.activeText].title}
                text={this.props.texts && this.props.texts[this.state.activeText].text} 
                headersColor={this.props.headersColor}
                textColor={this.props.textColor} />
            </TextSectionWrapper>

          </RightColumn>

        </LearnMoreStyledWrapper>

        <ShowCvButtonWrapper>
          <ShowCvButton
            onClick={this.openCV}>
            {this.state.displayCVResponsive ? 'CV ausblenden' : 'CV einblenden'}
          </ShowCvButton>
        </ShowCvButtonWrapper>

      </React.Fragment>
    )
  }
}

export default DoctorInfo;